import './Header.scss';

function Header() {
  return (
    <header>
      <div className='gradient-border'>
        <div className='cercle back'></div>
        <div className='planet'>
          <h1>Nicolas Gaillard-Melac</h1>
          <span>Développeur Web</span>
        </div>
        <div className='cercle front'></div>
      </div>
    </header>
  );
}

export default Header;