import './App.css';
import './SectionTechDoukro.scss';
import './SectionIntroduceDoukro.scss';
import './SectionKasa.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import SectionScreenshot from './components/Sections/Screenshot/Screenshot';

// <a href='https://serime.github.io/' target="_blank" rel="noreferrer">Lien du site</a>

function App() {
  return (
    <div className="App">
      <Header/>
      <section className='SectionFormation'>
        <h2>Formation Openclassrooms : Développeur Web</h2>
        <div className='Text'>
          <h3>Les missions types que je pourrai mener pour vous :</h3>
          <ol>
            <li><strong>Construire un site web responsive et dynamique</strong> s’adaptant à tout type d’écran grâce à l’intégration des éléments des maquettes graphiques (développement front-end avec HTML, CSS, JavaScript et React)</li>
            <li><strong>Créer des API et des bases de données</strong> pour développer des sites complets et dynamiques et assurer le bon fonctionnement côté serveur (développement back-end avec NodeJS, Express et MongoDB).</li>
            <li><strong>Optimiser les performances et réaliser la maintenance de sites web déjà existants</strong> pour permettre leur fonctionnement optimal et une bonne visibilité sur les moteurs de recherche (SEO).</li>
            <li><strong>Gérer un projet web de A à Z :</strong> de la planification du projet à la présentation de la solution au client, en passant par la rédaction des spécifications techniques.</li>
          </ol>
        </div>
      </section>
      <section className='SectionKasa'>
        <h2>Kasa</h2>
        <div className='Text'>
          <h3>Introduction</h3>
          <p>Développement du <strong>front-end</strong> d’un site de location d’hébergement avec <strong>React</strong>. À partir d'une maquette <strong>Figma</strong> avec l'utilisation de : <strong>React Router</strong> et <strong>SASS</strong>.</p>
          <h3>Difficulté du projet</h3>
          <p>Réalisation d'un carrousel infini animé. Les images sont placés de gauche à droite et animées avec une translation sur X.<br/><br/>
          La plus grosse difficulté pour ce composant fut la translation de la dernière image vers la première. Pour cela j'ai utilisé une copie de la dernière image placée à gauche de la première, pour ensuite pouvoir faire une translation de cette copie vers la première afin que visuellement la première image se trouve à la suite de la dernière.</p>
        </div>
        <div className='ImageKasa'>
          <img id='ImageMobile' src='Kasa_Mobile.png' alt="Capture d'écran mobile du site Kasa"></img>
          <img id='ImageDesktop' src='Kasa_Desktop.png' alt="Capture d'écran du site Kasa"></img>
        </div>
        <div className='buttons'>
          <a className='button' href='https://serime.github.io/' target="_blank" rel="noreferrer"><strong>Website</strong></a>
          <a className='button' href='https://github.com/Serime/OpenclassroomsP6/' target="_blank" rel="noreferrer">
            <img id='GitHub_Name' src='github-mark.svg' alt="Github"></img>
            <img id='GitHub_Logo' src='GitHub_Logo.png' alt="Github Logo"></img>
          </a>
        </div>
      </section>
      <section className='SectionIntroduceDoukro'>
        <h2>Mon projet du moment</h2>
        <p>Un jeu de carte en <strong>multijoueur temps réel</strong> dont les images des cartes sont généré par <strong>IA</strong></p>
        <div className='Card_Images'>
          <img src='Card_Images/Border crossing.png' alt="Carte Border crossing du jeu Doukro"></img>
          <img src='Card_Images/Former blacksmith.png' alt="Carte Former blacksmith du jeu Doukro"></img>
          <img src='Card_Images/Knight guarding the entrance to the city.png' alt="Carte Knight guarding the entrance to the city du jeu Doukro"></img>
          <img src='Card_Images/Luminous deity.png' alt="Carte Luminous deity du jeu Doukro"></img>
        </div>
      </section>
      <section className='SectionTechDoukro'>
        <h3>Architecture global du projet</h3>
        <p>Une application web <strong>React</strong> pour le front-end 
        <br/>Un back-end en <strong>NodeJS</strong> dans un conteneur <strong>Docker</strong> héberger sur un <strong>VPS</strong></p>
        <img className='LogoTech LogoReact' src='Tech_Logo/React.png' alt='Logo de React'/>
        <img className='LogoTech LogoMongoDB' src='Tech_Logo/MongoDB.png' alt='Logo de MongoDB'/>
        <div className='Docker'>
          <img className='LogoDocker' src='Tech_Logo/Docker.svg' alt='Logo de Docker'/>
          <div className='NodeJS'>
            <img className='LogoNodeJS' src='Tech_Logo/NodeJS.svg' alt='Logo de NodeJS'/>
            <div className='MongoConnection'></div>            
            <div className='ReactExpressConnectionRight'></div>
            <div className='ReactExpressMessageRight'>
              <span>Requête</span>
            </div>
            <div className='ReactExpressConnectionLeft'></div>
            <div className='ReactExpressMessageLeft'>
              <span>Réponse</span>
            </div>
            <div className="arrow ExpressRight"></div>
            <div className="arrow ExpressLeft"></div>
            <div className='ReactSocketIOConnection'></div>
            <div className='ReactSocketIOMessage'>
              <span>Nouveau Message</span>
            </div>
            <div className="arrow SocketIORight"></div>
            <div className="arrow SocketIOLeft"></div>
            <img className='LogoExpress' src='Tech_Logo/Express.png' alt='Logo de Express'/>
            <img className='LogoSocketIO' src='Tech_Logo/SocketIO.png' alt='Logo de SocketIO'/>
          </div>
        </div>        
      </section>
      <section className='SectionDifficultyDoukro'>
        <h3>Difficultés du projet</h3>
        <div className='Text'>
          <ul>
            <li><strong>Gestion d'un projet avec un front-end et back-end héberger sur le même serveur</strong>, utilisation de deux Dockerfiles, avec un fichier compose.yaml qui "build" le service front-end pour ensuite lancer service back-end en utilisant la version static du front-end.</li>
            <li><strong>Mise en place du HTTPS</strong>, création d'un certificat SSL avec certbot et Let's Encrypt et uniquement dans l'environnement de production, utilisation des certificats pour lancer un serveur HTTPS.</li>
            <li><strong>Reconnection d'un utilisateur en cours de parties</strong>, pour les utilisateurs qui actualisent leurs navigateurs web ou qui le fermer et le reouvre durant une partie. J'ai dû mettre en place un système qui renvoie les actions des joueurs lorsqu'un joueur se reconnecte.</li>
          </ul>
        </div>
      </section>
      <SectionScreenshot/>
      <Footer/>
    </div>
  );
}

export default App;
